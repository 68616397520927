<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button
        class="filter-item"
        type="primary"
        @click="checkDialogFormVisible(true, {}, 'CREATE')"
        >新增</el-button
      >
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      style="width: 100%;margin-bottom: 20px;"
      row-key="id"
      border
      default-expand-all
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="name" label="菜单名称" sortable width="280">
      </el-table-column>
      <el-table-column prop="type" label="类型" width="90">
        <template slot-scope="scope">
          {{ getValue(MENU_TYPE, scope.row.type) }}
        </template>
      </el-table-column>
      <el-table-column prop="path" label="路径"> </el-table-column>
      <el-table-column prop="icon" label="图标"> </el-table-column>
      <el-table-column prop="component_path" label="文件路径">
      </el-table-column>
      <el-table-column align="center" prop="created_at" label="创建时间">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="created_at" label="修改时间">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="checkDialogFormVisible(true, scope.row, 'CREATE')"
            >创建子菜单</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!--    编辑和新增-->
    <el-dialog
      :title="DIALOG_TITLE_TYPE[dialogType]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
        label-width="150px"
        style="width: 80%; margin-left:50px;"
      >
        <el-form-item label="菜单名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="菜单类型" prop="type">
          <el-select
            v-model="form.type"
            placeholder="请选择"
            style="width: 100%"
            class="filter-item"
            clearable
          >
            <el-option
              v-for="item in MENU_TYPE"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="页面地址" prop="path">
          <el-input v-model="form.path" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="图标（base64编码）" prop="icon">
          <el-input
            type="textarea"
            v-model="form.icon"
            :autosize="{ minRows: 2, maxRows: 8 }"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item label="页面路由地址" prop="componentPath">
          <el-input v-model="form.componentPath" placeholder="请输入" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkDialogFormVisible(false, {}, '')"
          >取消</el-button
        >
        <el-button
          type="primary"
          @click="saveAddOrEditData"
          :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { createMenu, getMenuList } from "@/api/menu";
import { DIALOG_TITLE_TYPE, getValue, MENU_TYPE } from "@/utils/enumUtile";
import { formatApiDate } from "@/utils/utils";

export default {
  computed: {
    DIALOG_TITLE_TYPE() {
      return DIALOG_TITLE_TYPE;
    },
    MENU_TYPE() {
      return MENU_TYPE;
    },
    formatApiDate() {
      return formatApiDate;
    },
    getValue() {
      return getValue;
    }
  },
  components: {},
  filters: {},
  data() {
    return {
      list: [],
      listLoading: true,
      listQuery: {},
      dialogFormVisible: false, // 新增 or 编辑的弹框
      dialogType: "",
      rules: {
        type: [{ required: true, message: "类型不能为空", trigger: "change" }],
        name: [
          { required: true, message: "菜单名称不能为空", trigger: "change" }
        ]
      },
      form: {
        parentId: 0,
        type: "",
        name: "",
        path: "",
        icon: "",
        componentPath: ""
      },
      btnLoading: false
    };
  },
  created() {
    this.getList();
  },
  watch: {},
  methods: {
    /**
     * 查询用户列表
     */
    getList() {
      this.listLoading = true;
      getMenuList(this.listQuery)
        .then(response => {
          this.list = response.data || [];
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    /**
     * 切换状态
     * @param status
     * @param editObj
     * @param type
     */
    checkDialogFormVisible(status, editObj = {}, type) {
      if (editObj && Object.keys(editObj).length !== 0) {
        this.form.parentId = editObj.id;
      }
      if (status && type === "UPDATE") {
        this.form.type = editObj.type;
        this.form.name = editObj.name;
        this.form.icon = editObj.icon;
        this.form.path = editObj.path;
        this.form.parentId = editObj.parentId;
        this.form.componentPath = editObj.componentPath;
      }
      if (!status) {
        this.form.type = "";
        this.form.name = "";
        this.form.icon = "";
        this.form.path = "";
        this.form.parentId = "";
        this.form.componentPath = "";
      }
      this.dialogType = type;
      this.dialogFormVisible = status;
      this.$validator.reset();
    },
    /**
     * 确认添加还是编辑
     */
    saveAddOrEditData() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const form = this.form;
          const params = {
            type: form.type,
            parentId: form.parentId,
            name: form.name,
            path: form.path,
            icon: form.icon,
            componentPath: form.componentPath
          };
          this.btnLoading = true;
          createMenu(params)
            .then(() => {
              this.getList();
              this.checkDialogFormVisible(false);
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
            })
            .finally(() => {
              this.btnLoading = false;
            });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
